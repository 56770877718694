import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() {}

  public moment() {
    return moment();
  }

  /**
   * Waits asynchronously for a certain amount of milliseconds.
   *
   * @param milliseconds The amount of milliseconds to wait.
   */
  public async sleep(milliseconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(() => resolve(), milliseconds));
  }
}
