import { ActionSheetController, GestureController, Platform } from "@ionic/angular";
import { PhoneService } from "../../webphone/phone.service";
import { TimeService } from "../../webphone/time.service";
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CrudService } from "../../../services/crud.service";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { UtilService } from "../../../services/util.service";



@Component({
  selector: 'incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss'],
})
export class IncomingCallComponent implements OnInit, OnDestroy {
  public dialerThemeSetting = {
    backgroundColor: '#ffffff',
    buttonBackgroundColor: '#000000',
    buttonTextColor: '#ffffff',
    dialButtonTextColor: '#ffffff',
    dialButtonBackgroundColor: '#90ee90',
    buttonAlphabetColor: '#d3d3d3',
    inputBackgroundColor: '#ffffff',
    inputColor: '#000000',
    instructionTextColor: '#ffffff'
  }
  public userRoomNumber = this.phoneService.getParameter('displayname');
  private lastOnStart: number = 0;
  private DOUBLE_CLICK_THRESHOLD: number = 500;
  timer: any = 0;
  preventSimpleClick: boolean = false;
  status: string;
  @ViewChild('disconnected', {
    static: false
  }) disconnected: any;
  @ViewChild('appTimer', {
    static: false
  }) appTimer: any;

  audioIncoming: HTMLAudioElement;
  muteOn: boolean = false
  onCallStateChangedEventSubscriber: Subscription = null;
  public isCalling: any = null;
  public selectedDevice: string = '';
  constructor(
    private phoneService: PhoneService,
    private timeService: TimeService,
    private crudService: CrudService,
    private gestureCtrl: GestureController,
    private platform: Platform,
    private _zone: NgZone,
    private actionSheetController: ActionSheetController,
    private utilService: UtilService
  ) { }

  ngOnDestroy(): void {
    if (this.onCallStateChangedEventSubscriber) {
      this.onCallStateChangedEventSubscriber.unsubscribe();
    }
  }
  async ngOnInit() {
    this.dialerThemeSetting = this.crudService.propertyConfig$.value?.dialerThemeSetting?.value || this.dialerThemeSetting;
    this.onCallStateChangedEventSubscriber = this.phoneService.isCalling$.pipe(tap((e) => {
      this._zone.run(() => {
        this.isCalling = e;
        if (!e || !e?.callStatus) {
          this.phoneService.keypadModel.next({
            to: '', showKeypad: true
          })
        }
        if (e && e?.callStatus === 'connected') {
          setTimeout(async () => {
            const deviceList = await this.phoneService.getdevicelist() as string[];
            if (deviceList?.length && this.utilService.isMobileDevice()) {
              const hasHeadsetDevice = deviceList[0].split('\r\n').filter((name) => !!name && (name.toLowerCase().includes('headset') || name.toLowerCase().includes('earpiece')));
              if (hasHeadsetDevice?.length) {
                this.selectedDevice = hasHeadsetDevice[0];
                await this.phoneService.setdevice(hasHeadsetDevice[0]);
              }
            }
          }, 1000);
        }
      })
    })).subscribe();
    setTimeout(() => {
      const disconnectedGesture = this.gestureCtrl.create({
        gestureName: 'dbclick',
        el: this.disconnected.nativeElement as any,
        threshold: 0,
        onStart: () => { this.onStart(); }
      });
      disconnectedGesture.enable();
    }, 1000)
    this.selectedDevice = await this.phoneService.getdevice() as string;
  }


  async acceptIncomingCall() {
    this._zone.run(() => {
      this.phoneService.acceptCall();
      this.appTimer?.startTimer();
    })
  }

  async terminateIncomingCall() {
    this._zone.run(() => {
      this.appTimer?.clearTimer();
      this.phoneService.terminateCall();
    })
  }

  async mute() {
    this.muteOn = !this.muteOn
    await this.phoneService.muteUnmuteCall(this.muteOn)
  }
  isMute = false
  async muteUnmuteCall() {
    this.isMute = !this.isMute
    await this.phoneService.muteUnmuteCall(this.isMute)
  }


  simpleClickFunction(): void {
    this.timer = 0;
    this.preventSimpleClick = false;
    let delay = 400;

    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        //whatever you want with simple click go here
        this._zone.run(() => {
          if (this.platform.is('ios') || this.platform.is('iphone') || this.platform.is('ipad')) {
            this.terminateIncomingCall();
          }
        })
      }
    }, delay);

  }

  doubleClickFunction(): void {
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    //whatever you want with double click go here
    this._zone.run(() => {
      this.terminateIncomingCall();
    })
  }

  private onStart() {
    const now = Date.now();
    if (Math.abs(now - this.lastOnStart) <= this.DOUBLE_CLICK_THRESHOLD) {
      this.doubleClickFunction();
      this.lastOnStart = 0;
    } else {
      this.lastOnStart = now;
      this.simpleClickFunction();
    }
  }

  async onActionSheetSelect() {
    const deviceList = await this.phoneService.getdevicelist() as string[];
    const audioActionSheet = await this.actionSheetController.create({
      cssClass: 'my-custom-class',
      buttons: deviceList[0].split('\r\n').filter((name) => !!name).map((name) => ({
        text: name,
        data: {
          name,
        },
        handler: async () => {
          console.log(`Audio =======`, name);
          try {
            this.selectedDevice = name;
            await this.phoneService.setdevice(name);
          } catch (error) {
            console.error('error ===============', error)
          }
        }
      }))
    });
    await audioActionSheet.present();
  }
}
