import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RoomServiceModal } from "./home/room-service/room-service.page"
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptorService } from './services/request-interceptor.service';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { ComponentsModule } from './component/components.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ModalComponent } from './component/modal/modal.component';
import { environment } from '../environments/environment';
import { ChatService } from './services/chat.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { AddOccupantPage } from './add-occupant/add-occupant.page';
import { AliceServiceModal } from './home/alice-service/alice-service.page';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StaticConversationModal } from './conversation/static-conversation-modal/static-conversation-modal.page';
// import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { GuestCallingService } from './services/calling';
import { IonicGestureConfig } from './long-press.directive'
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
const socketConfig: SocketIoConfig = { url: environment.WS_ENDPOINT, options: { autoConnect: false }, };


@NgModule({
  declarations: [AppComponent, RoomServiceModal, AddOccupantPage, AliceServiceModal, StaticConversationModal],
  entryComponents: [HeaderComponent, FooterComponent, ModalComponent, StaticConversationModal, RoomServiceModal, AliceServiceModal],
  imports: [
    FormsModule, 
    ReactiveFormsModule,
    BrowserModule, BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md'
    }), 
    AppRoutingModule, 
    HttpClientModule, 
    ComponentsModule,
    SocketIoModule.forRoot(socketConfig), 
    SelectDropDownModule, 
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production,
      scope: '/',
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    ChatService, GuestCallingService, Vibration, AndroidPermissions, BackgroundMode
  ],
  bootstrap: [AppComponent],
  exports: [SocketIoModule]
})
export class AppModule { }
