import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { RouterModule } from '@angular/router';
import { ModalComponent } from "./modal/modal.component";
import { WeekDayComponent } from './week-day/week-day.component';
import { OnloadingComponent } from "./onloading/onloading.component";
import { KeypadComponent } from './calling/keypad/keypad.component';
import { ScreenLockComponent } from './calling/screen-lock/screen-lock.component';
import { HomeBtnsComponent } from "./homecomponenets/home-btns/home-btns.component";
import { HomeBannerComponent } from "./homecomponenets/home-banner/home-banner.component";
import { LongPressModule } from 'ionic-long-press';
import { IncomingCallComponent } from "./calling/incoming-call/incoming-call.component";
import { OutgoingCallComponent } from "./calling/outgoing-call/outgoing-call.component";
import { TimerComponent } from "./calling/timer/timer.component";
import { SwiperModule } from "swiper/angular";

export const components: any = [
  HeaderComponent, FooterComponent, ModalComponent, WeekDayComponent,
  OnloadingComponent, KeypadComponent, ScreenLockComponent,
  HomeBtnsComponent, HomeBannerComponent,
  OutgoingCallComponent, IncomingCallComponent, TimerComponent
]


@NgModule({
  declarations: components,
  imports: [
    IonicModule, CommonModule, FormsModule, RouterModule,
    LongPressModule, SwiperModule
  ],
  exports: components
})
export class ComponentsModule { }
