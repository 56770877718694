import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-onloading',
  templateUrl: './onloading.component.html',
  styleUrls: ['./onloading.component.scss'],
})
export class OnloadingComponent implements OnInit {

  @Input() text: string | undefined = "Connecting to servers"
  constructor() { }

  ngOnInit() {}

}
