import { Injectable } from "@angular/core";
import { App } from "@capacitor/app";
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';


@Injectable({
    providedIn: 'root',
  })
  export class BackgroundRunnerProvider {

    interval: any;

    constructor(
        private backgroundmode: BackgroundMode,
    ) {

    }

    async init() {
        this.backgroundmode.setDefaults({ silent: true })
        App.addListener('appStateChange', async ({ isActive }) => {
            if(isActive == false) this.backgroundmode.enable();
            if(isActive == true) {
                this.backgroundmode.disable();
                clearInterval(this.interval)
            }
        });
    }

    processFuncInBackground(callback: any = () => {}) {
        this.backgroundmode.on('activate').subscribe(async () => {
            console.log("Background mode activated")
            this.interval = setInterval(() => {
                callback();
            }, 360000)
        })
    }
}