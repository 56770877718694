import { Injectable } from '@angular/core';
import { WebPhoneService } from './webphone.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { UtilService } from '../../services/util.service';
import { Router } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { PushNotifications } from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  public isWebRtcInitialize: boolean = false;
  public usingPublicUser: boolean = false;
  public onCallStateChangedEvent = new Subject();
  public onCallLogChangedEvent = new Subject();
  public registerGlobalUserInProgress: boolean = false;
  public keypadModel: BehaviorSubject<{
    to: string;
    showKeypad: boolean;
  }> = new BehaviorSubject({
    to: '', showKeypad: true
  });
  propertyId;
  public isUserRegistered$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isWebRtcLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public redialNumber: string = ''
  selectedAudioDevice = "speaker";
  public isCalling$: BehaviorSubject<any> = new BehaviorSubject(null);
  public enableInboundConfig: boolean = true;
  public propertyConfiguration:any = {};
  constructor(
    private router: Router,
    private platform: Platform,
    private webPhoneService: WebPhoneService, private utilService: UtilService) {
  }

  /**
   * This method used to set after user loggedIn it was register for web phone
   * @param value 
   * @returns same input value
   */
  public setRegistered(value: boolean): boolean {
    console.log('called ============== register', value);
    if (this.isUserRegistered$.value !== value) {
      this.isUserRegistered$.next(value);
    }
    return value;
  }

  /**
   * This method used to set after web phone loaded in PWA
   * Before start sip registration it should be true 
   * @param value 
   * @returns same input value
   */
  public isWebRTCLoaded(value: boolean): boolean {
    this.isWebRtcLoaded$.next(value);
    return value;
  }

  /**
   * This method used to unregister on logout or page refresh
   */
  public async unregister() {
    if (this.isUserRegistered$.value) {
      await this.webPhoneService.unregister();
    }
  }

  /**
   * This method used to stop web phone
   * But we are stoping on unregister after logout 
   */
  public async stop() {
    await this.webPhoneService.stop();
  }

  /**
   * This method used accept incoming call
   */
  public async acceptCall() {
    try {
      const acceptCall = await this.webPhoneService.acceptCall();
      localStorage.removeItem('incomingcall')
    } catch (error) {
    }
  }

  /**
   * This method used to hangup incoming/outgoing call
   */
  public async terminateCall() {
    try {
      await this.webPhoneService.hangup();
      localStorage.removeItem('incomingcall')
    } catch (error) {
      console.error('terminateCall ========', error)
    }

  }
  /**
   * This method used to hangup incoming/outgoing call
   * @param callback This callback used for set default sip parameter after webphone loaded
   */
  public async initRegister(callBack): Promise<void> {
    try {
      this.webPhoneService.register(() => {
        this.isWebRTCLoaded(true);
        callBack && callBack();
      }, (value) => {
        this.setRegistered(value);
        console.log('propertyConfiguration.enableCallingPush?.value', this.propertyConfiguration.enableCallingPush?.value)
        if (this.propertyConfiguration.enableCallingPush?.value) {
          this.webPhoneService.onlyRegister();
        }
      }, async (value) => {
        if (!this.enableInboundConfig) {
          if (value[0] === 'ringing' && value[1] !== 1) {
            this.terminateCall();
            return console.log('Not allow permission for incoming call')
          }
        }
        console.log('sip Logs: ', value)
        if (value[0] === 'initializing' || value[0] === 'setup' || value[0] === 'trying' || value[0] === 'ringing') {
          const state: string = value[1] === 1 ? 'Outgoing' : 'Incoming';
          this.isCalling$.next({
            callType: value[1] === 1 ? 'outgoing' : 'incoming',
            calling: value[2],
            callStatus: 'connecting'
          });
          if (value[0] === 'ringing' && value[1] !== 1) {
            localStorage.setItem('incomingcall', JSON.stringify({
              callType: 'incoming',
              calling: value[2],
              callStatus: 'connecting'
            }))
            await this.router.navigate(['dialer'])
            if (this.platform.is('capacitor')) StatusBar.setBackgroundColor({ color: '#2dd36f' });
          }
          if (state === 'Incoming' && value[0] === 'setup') {
            this.utilService.callNotificationManager('Call_Reuqest', {
              title: `${state} call`,
              body: `You have an ${state} call`,
              CallerID: value[2],
              actionType: 'Call_Reuqest'
            })
          }
        } else if (value[0] === 'connected') {
          this.utilService.clearNotifications()
          localStorage.removeItem('incomingcall')
          this.isCalling$.next({
            callType: value[1] === 1 ? 'outgoing' : 'incoming',
            calling: value[2],
            callStatus: 'connected'
          })
        } else if (value[0] === 'disconnected') {
          this.utilService.clearNotifications()
          setTimeout(async () => {
            this.redialNumber = value[1] === 1 ? value[2] : '';
            this.keypadModel.next({
              to: '', showKeypad: true
            });
            this.isCalling$.next(null);
            if (this.usingPublicUser) {
              this.usingPublicUser = false;
              this.unregister();
            }

            if (this.platform.is('capacitor')) StatusBar.setBackgroundColor({ color: '#002856' });
            clearInterval(this.utilService._interval);
            clearInterval(this.utilService.interval);
            const info = JSON.parse(localStorage.getItem('incomingcall'));
            if (info && info.calling) {
              await this.utilService.showLocalNotification('Missed_Call', {
                title: `Missed call`,
                body: `You have a missed call`,
                CallerID: `${info.calling}`,
                actionType: 'Missed_Call'
              })
              localStorage.removeItem('incomingcall');
            }
          }, 200)
        }
      });
    } catch (e) {
      console.error('Error: initRegister', e);
    }
  }

  /**
   * This method used for set default sip parameters
   */
  public setSipDefaultParameter() {
    this.webPhoneService.setSipDefaultParameter();
  }

  /**
   * This method used to start web phone after login or loggedIn user refresh
   */
  public onWTCStart(sipParameters, enableInboundConfig) {
    this.enableInboundConfig = enableInboundConfig;
    this.webPhoneService.onWTCStart(sipParameters);
  }

  /**
   * This method used for produce sound after user tap on number button
   */
  public async sendDtmf(character: string): Promise<void> {
    try {
      this.webPhoneService.sendDtmf(character);
    } catch (error) {
      console.error('error ==================> sendDtmf', error);
    }
  }

  public async callTo(options: { to: string, propertyConfig: any }): Promise<any> {
    if (!options.propertyConfig.featureWebRTC?.value) {
      return console.log('Disable web rtc');
    }
    if (!options.to) {
      return this.utilService.showToast('Enter dial number');
    }
    if (!options.propertyConfig.enableOutboundConfig?.value) {
      return this.utilService.showToast('No permission is allowed to call')
    }
    if (!this.isUserRegistered$.value && !this.registerGlobalUserInProgress) {
      this.registerGlobalUser(options.propertyConfig);
      const regSub = this.isUserRegistered$
        .pipe(
          take(2),
          tap((register) => {
            if (register) {
              this.call({ address: options.to });
              regSub?.unsubscribe()
            }
          })
        )
        .subscribe()
      this.isCalling$.next({
        callType: 'outgoing',
        calling: options.to,
        callStatus: 'connecting'
      });
    } else {
      this.isCalling$.next({
        callType: 'outgoing',
        calling: options.to,
        callStatus: 'connecting'
      });
      this.call({ address: options.to });
    }
  }

  public async call(options: { address: string }): Promise<any> {
    if (!options.address) {
      return;
    }
    try {
      console.log(`this.webPhoneService.getParameter('displayname')`, this.webPhoneService.getParameter('displayname'))
      const callOptions = {
        to: options.address,
        displayName: this.webPhoneService.getParameter('displayname')
      }
      const call = await this.webPhoneService.call(callOptions);
    } catch (error) {
      console.error('error ============= call', error)
    }
  }

  /**
   * This method used for mute unmute call
   */
  public async muteUnmuteCall(mute: boolean) {
    try {
      this.webPhoneService.mute(mute)
      return mute;
    } catch (error) {
      console.error('muteUnmuteCall ================', error)
    }
  }

  /**
   * This method used for get parameter to check sip parameter set or not
   */
  public getParameter(key: string) {
    return this.webPhoneService.getParameter(key)
  }


  minlimit = 50
  maxlimit = 250
  limitCrossed = false



  /**
   * This method used for clear call state but we are not using anymore
   */
  clearCallState() {
    this.keypadModel.next({
      to: '', showKeypad: true
    });
  }


  /**
   * This method used for get all connected audio device 
   */
  public getdevicelist() {
    return this.webPhoneService.getdevicelist();
  }

  /**
   * This method used for set selected device
   */
  public setdevice(name: string) {
    return this.webPhoneService.setdevice(name);
  }

  /**
   * This method used for open device selection popup but we are not using anymore
   */
  public devicepopup() {
    return this.webPhoneService.devicepopup();
  }

  /**
   * This method used for get selected device
   */
  public getdevice() {
    return this.webPhoneService.getdevice();
  }

  /**
   * Register global user for outgoing calls
   * @param propertyConfig 
   */
  public registerGlobalUser(propertyConfig) {
    this.registerGlobalUserInProgress = true;
    this.webPhoneService.stop();
    this.webPhoneService.webphoneStopEvents.pipe(
      take(1),
      tap(() => {
        // setTimeout(() => {
        //   this.registerGlobalUserInProgress = false;
        //   if (this.isUserRegistered$.value && this.usingPublicUser) {
        //     this.usingPublicUser = false;
        //     this.unregister();
        //   }
        // }, 15000);
        if (!propertyConfig.sipRealm?.value) {
          return;
        }
        const sipParameters = [{
          key: 'resetsettings', value: true
        }, {
          key: 'serveraddress', value: propertyConfig.sipRealm?.value
        }, {
          key: 'username', value: propertyConfig.sipWebRtcUser?.value
        }, {
          key: 'password', value:  propertyConfig.sipWebRtcPassEncrypted?.value || propertyConfig.sipPassEncrypted?.value
        }, {
          key: 'customsipheader', value: propertyConfig.enableCallingPush?.value ? `X-HG: PID=${this.propertyId}\r\nX-HG-PUSH: app-id=io.hotelgenie.app^pn-tok=121212^pn-type=hotelgenie` : `X-HG: PID=${this.propertyId}`
        }, {
          key: 'webrtcserveraddress', value: propertyConfig.sipWssAddress?.value || propertyConfig.sipWssAddressBackup?.value
        }, {
          key: 'loglevel', value: 5
        }, {
          key: 'ssidcode', value: "k8qhh3mrt"
        }, {
          key: 'cancloseaudioline', value: 3
        }, {
          key: 'singleaudiostream', value: 5
        }, {
          key: 'useragent', value: `hotelGenie/${navigator.userAgent}`
        }, {
          key: 'displayname', value: propertyConfig.skipButtonCall ?
            `RM${localStorage.getItem('room')}-${localStorage.getItem('guestDialName')}` :
            `RM${localStorage.getItem('room')}-${localStorage.getItem('firstName')}`
        },{
          key: 'regtimeout', value: 0
        }];
        this.usingPublicUser = true;
        this.onWTCStart([...sipParameters, ...(propertyConfig.advanceSipParameters?.value || [])], false);
      })
    ).subscribe()

  }
}
