import { Component, NgZone, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { GestureController } from '@ionic/angular';
import { PhoneService } from '../../webphone/phone.service';
import { KeypadService } from '../../webphone/keypad.service';
import { CrudService } from 'src/app/services/crud.service';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
@Component({
  selector: 'keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.scss']
})
export class KeypadComponent implements OnInit, OnDestroy {
  @Input()
  public propertyId;
  public dialerThemeSetting = {
    backgroundColor: '#ffffff',
    buttonBackgroundColor: '#000000',
    buttonTextColor: '#ffffff',
    dialButtonTextColor: '#ffffff',
    dialButtonBackgroundColor: '#90ee90',
    buttonAlphabetColor: '#d3d3d3',
    inputBackgroundColor: '#ffffff',
    inputColor: '#000000'
  }
  activeIndex = ''

  constructor(private gestureCtrl: GestureController, private _zone: NgZone, private phoneService: PhoneService, private keypadService: KeypadService, private crudService: CrudService) {
  }
  timer: any = 0;
  preventSimpleClick: boolean = false;
  @ViewChild('callBtn', {
    static: false
  }) callBtn: any;
  private lastOnStart: number = 0;
  private DOUBLE_CLICK_THRESHOLD: number = 500;
  public isWTCRegistered = true;
  private registerSubscriber: Subscription = null;
  private keypadValueSubscriber: Subscription = null;
  private onCallStateChangedEventSubscriber: Subscription = null;
  public isCalling: any = null;
  public keypadModel: string = '';
  public showKeypad: boolean = true;
  dialAction() {
    this.phoneService.callTo({ to: this.phoneService.keypadModel.value.to, propertyConfig: this.crudService.propertyConfig$.value })
  }

  redialAction() {
    this.phoneService.keypadModel.next({
      to: this.phoneService.redialNumber, showKeypad: true
    })
    this.phoneService.callTo({ to: this.phoneService.redialNumber, propertyConfig: this.crudService.propertyConfig$.value })
  }

  ngOnDestroy(): void {
    this.registerSubscriber?.unsubscribe();
    this.onCallStateChangedEventSubscriber?.unsubscribe();
    this.keypadValueSubscriber?.unsubscribe();
  }

  simpleClickFunction(): void {
    this.timer = 0;
    this.preventSimpleClick = false;
    let delay = 400;

    this.timer = setTimeout(() => {
      if (!this.preventSimpleClick) {
        //whatever you want with simple click go here
        this._zone.run(() => {
          this.dialAction();
        })
      }
    }, delay);

  }

  doubleClickFunction(): void {
    this.preventSimpleClick = true;
    clearTimeout(this.timer);
    //whatever you want with double click go here
    this._zone.run(() => {
      this.redialAction();
    })
  }

  async ngOnInit() {
    this.dialerThemeSetting = this.crudService.propertyConfig$.value?.dialerThemeSetting?.value || this.dialerThemeSetting;
    this.registerSubscriber = this.phoneService.isUserRegistered$
      .pipe(tap((value: boolean) => {
        // this.isWTCRegistered = value;
      }))
      .subscribe();
    this.keypadValueSubscriber = this.phoneService.keypadModel
      .pipe(tap((value: {
        to: string, showKeypad: boolean;
      }) => {
        this.keypadModel = value.to;
        this.showKeypad = value.showKeypad
      }))
      .subscribe();
    this.onCallStateChangedEventSubscriber = this.phoneService.isCalling$
      .pipe(
        tap((e) => {
          this._zone.run(() => {
            this.isCalling = e;
            if (!e || !e?.callStatus) {
              this.phoneService.keypadModel.next({
                to: '', showKeypad: true
              });
            }
          })
        })
      ).subscribe()
    setTimeout(() => {
      const gesture = this.gestureCtrl.create({
        gestureName: 'dbclick',
        el: this.callBtn.el as any,
        threshold: 0,
        onStart: () => { this.onStart(); }
      });
      gesture.enable();
    }, 1000)
  }

  private onStart() {
    const now = Date.now();
    if (Math.abs(now - this.lastOnStart) <= this.DOUBLE_CLICK_THRESHOLD) {
      this.doubleClickFunction();
      this.lastOnStart = 0;
    } else {
      this.lastOnStart = now;
      this.simpleClickFunction();
    }
  }


  getAudioDeviceIcon() {
    if (this.phoneService.selectedAudioDevice === 'bluetooth') {
      return 'bluetooth';
    } else if (this.phoneService.selectedAudioDevice === 'headset') {
      return 'headset';
    } else {
      return 'volume-high-outline';
    }
  }

  public terminateCall() {
    return this.phoneService.terminateCall()
  }

  public pressBackSpace() {
    this.keypadService.pressBackSpace();
  }

  public pressClear() {
    this.keypadService.pressClear();
  }

  public pressDigit(digit: string) {
    this.activeIndex = digit
    this.keypadService.pressDigit(digit);
    if (!this.phoneService.isUserRegistered$.value && !this.phoneService.registerGlobalUserInProgress) {
      // this.phoneService.registerGlobalUser(this.crudService.propertyConfig$.value)
    }

    setTimeout(() => {
      this.activeIndex = '';
    }, 200);
  }

}
