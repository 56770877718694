import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SwPush } from '@angular/service-worker';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { User } from '../model/user';
import { WEB_PUSH_SERVER_KEY, GOOGLE_API_KEY } from '../utils/config';
import { CookieService } from 'ngx-cookie-service';
import { Platform } from '@ionic/angular';


@Injectable({
    providedIn: 'root',
})
export class CrudService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private currentUserModeSubject: BehaviorSubject<string>;
    public currentUserMode: Observable<string>;
    public webSubscription: any = null;
    public isRefreshed: boolean = false;
    public googleApiKey: string = GOOGLE_API_KEY;
    public configuration = {}
    public buttons = {} //contains pwa home buttons
    private authToken: string = null; // contains user's authentication token
    public propertyConfig$: BehaviorSubject<any> = new BehaviorSubject({});
    public slugConfiguration$: BehaviorSubject<any> = new BehaviorSubject({});
    public slug: BehaviorSubject<string> = new BehaviorSubject(this.cookieService.get('slug') || '');
    constructor(private http: HttpClient, private _swPush: SwPush, private cookieService: CookieService, private platform: Platform) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('auth') || null));
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentUserModeSubject = new BehaviorSubject<string>(localStorage.getItem('mode') || '');
        this.currentUserMode = this.currentUserModeSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.getValue();
    }

    public get currentUserModeValue(): string {
        return this.currentUserModeSubject.getValue();
    }

    public setUser(user, mode) {
        if (user) {
            localStorage.setItem('auth', JSON.stringify(user));
            if (mode === 'staff') {
                console.log('Staff user logging successfully')
            }
        } else {
            localStorage.removeItem('auth');
        }
        localStorage.setItem('mode', mode);
        this.currentUserModeSubject.next(mode);
        this.currentUserSubject.next(user);
    }

    public set setPropertyConfig(propertyConfig: any) {
        this.propertyConfig$.next(propertyConfig);
    }

    public set setSlugConfiguration(slugConfiguration: any) {
        this.slugConfiguration$.next(slugConfiguration);
    }

    public get getAuthToken(): string {
        return this.authToken;
    }

    public set setAuthToken(authToken: string) {
        this.authToken = authToken;
    }


    // sends request to fetch data from server
    public getData(url: string, params: { [key: string]: any } = {}): Observable<any> {
        return this.http.get(environment.API_ENDPOINT + url, { params });
    }

    // sends request to save/manipulate data
    public saveData(url: string, body: any): Observable<any> {
        return this.http.post(environment.API_ENDPOINT + url, body, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': ''
            }
        });
    }
    // sends request to logout
    public logout(url: string, body: any): Observable<any> {
        return this.http.post(environment.API_ENDPOINT + url, body, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': ''
            }
        });
    }

    // sends request to save/manipulate data
    public saveDataWithApiKey(url: string, body: any, apiKey: string): Observable<any> {
        return this.http.post(environment.API_ENDPOINT + url, body, {
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': apiKey
            }
        });
    }

    // sends request to manipulate data
    public updateData(url: string, body: any): Observable<any> {
        return this.http.put(environment.API_ENDPOINT + url, body, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    // sends request to manipulate data
    public patchData(url: string, body: any): Observable<any> {
        return this.http.patch(environment.API_ENDPOINT + url, body, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    // sends request to save/manipulate data
    public deleteData(url: string): Observable<any> {
        return this.http.delete(environment.API_ENDPOINT + url);
    }

    public sipCall(url: string, payload: any): Observable<any> {
        return this.http.post(url, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    public getAllPlaceByPropertyId(propertyId: string): Observable<any> {
        return this.http.get(`${environment.API_ENDPOINT}place/${propertyId}`);
    }

    public getLikePropertyByGuestIdOrRoomId(propertyId: string, roomId: string, guestId: string): Observable<any> {
        return this.http.get(`${environment.API_ENDPOINT}place/guest-trip-vault`, {
            params: {
                propertyId, roomId, guestId
            }
        });
    }

    public likeDislikePropertyByGuestId(propertyId: string, roomId: string, guestId: string, placeId: string): Observable<any> {
        return this.http.post(`${environment.API_ENDPOINT}place/like-dislike`, {
            propertyId, roomId, guestId, placeId
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    public getAllConfigurations(): Observable<any> {
        let slug = this.slug.getValue();
        if (!slug) {
            this.setSlug();
            slug = this.slug.getValue();
            if (!slug)
            throw Error('No slug found')
        }
        return this.getData(`property/ids-by-slug/${slug}`)
            .pipe(
                switchMap((slugConfig) => {
                    console.log('Slug Config', slugConfig)
                    return combineLatest([of(slugConfig), this.getData(`configuration/configuration-auth/property`)])
                })
            )
        // return combineLatest([this.getData(`property/slug-config/${subdomain}`), this.getData(`configuration/configuration-auth/property`)])
    }

    isStaffUserAuthenticate(isRefreshed: boolean): Observable<any> {
        const accessToken = this.getAuthToken || localStorage.getItem('token');
        const isStaffModeToken = this.currentUserModeValue === 'staff';
        if (!accessToken) {
            return of(null);
        }
        if (isRefreshed && accessToken && isStaffModeToken && Object.keys(this.slugConfiguration$.value || {}).length && Object.keys(this.propertyConfig$.value || {}).length) {
            const currentUser = this.currentUserValue || {};
            return of(currentUser);
        }
        return this.http.get<any>(`${environment.API_ENDPOINT}user/authenticate?accessToken=${accessToken}`)
            .pipe(
                map((res) => {
                    if (res && res.response_data && res.response_data.token) {
                        const user = res.response_data;
                        user.permissions = user?.accessGroup?.keys?.reduce((permissions, { accessKey }) => {
                            permissions[accessKey] = accessKey;
                            return permissions;
                        }, {}) || {};
                        this.setUser(user, 'staff');
                        // this.isRefreshed = true;
                        return user.response_data;
                    }
                    localStorage.clear();
                    return of(null);
                }),
                map(() => (this.isRefreshed && Object.keys(this.slugConfiguration$.value || {}).length && Object.keys(this.propertyConfig$.value || {}).length ? of([{
                    response_data: this.slugConfiguration$.value
                }, {
                    configuration: this.propertyConfig$.value
                }]) : this.getAllConfigurations())),
                catchError(() => {
                    return of(null)
                })
            );
    }

    public getAllRoomServiceRequest(propertyId: string, type: string, offset: number, limit: number, assignedUserIds: number[] = []) {
        return this.http.post(`${environment.API_ENDPOINT}room-service/staff-admin-tasks/${propertyId}/${type}`, {
            propertyId: Number(propertyId),
            offset, limit, assignedUserIds
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    public getWorkerTaskRequest(propertyId: string, type: string, offset: number, limit: number, assignedUserIds: number[] = []) {
        return this.http.post(`${environment.API_ENDPOINT}room-service/staff-worker-tasks/${propertyId}/${type}`, {
            propertyId: Number(propertyId),
            offset, limit, assignedUserIds
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }


    public getAllRoomServiceRequestById(roomServiceRequestId: string) {
        return this.http.get(`${environment.API_ENDPOINT}room-service/${roomServiceRequestId}`)
    }

    public getAllStaffWorkers(propertyId: string) {
        return this.http.get(`${environment.API_ENDPOINT}user/staff-workers`, {
            params: {
                'propertyId': propertyId
            }
        })
    }

    public updateRoomServiceRequest(id: string, body: any) {
        return this.http.put(`${environment.API_ENDPOINT}room-service/${id}`, body, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    public updateAssignee(assignedTo: string | number, body: any = {}) {
        return this.http.put(`${environment.API_ENDPOINT}room-service/update-assignee/${assignedTo}`, body, {
            headers: {}
        });
    }

    public completeRoomServiceRequest(id: string, body: any) {
        return this.http.put(`${environment.API_ENDPOINT}room-service/complete/${id}`, body, {
            'headers': {}
        });
    }

    public emergencyTrigger(body: any) {
        return this.http.post(`${environment.API_ENDPOINT}guest-notifications/emergency`, body, {
            'headers': {}
        });
    }

    public getAllRooms(propertyId) {
        return this.http.get(`${environment.API_ENDPOINT}room/all`, {
            params: {
                'propertyId[eq]': propertyId,
                offset: String(0), limit: String(0)
            }
        })

    }

    public getRequests(propertyId, condition = null) {
        if (condition) {
            return this.http.get(`${environment.API_ENDPOINT}housekeeping-item/all`, {
                params: {
                    'propertyId[in]': propertyId,
                    offset: String(0), limit: String(0)
                }
            })
        } else {
            return this.http.get(`${environment.API_ENDPOINT}housekeeping-item/all`, {
                params: {
                    'propertyId[eq]': propertyId,
                    offset: String(0), limit: String(0)
                }
            })
        }
    }

    async requestSubscription({
        userId, guestId
    }: {
        userId?: number | null, guestId?: number | null
    }) {
        try {
            if (!this._swPush.isEnabled) {
                console.log("Notification is not enabled.");
                return;
            }
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            console.log('requestSubscription ==================', result, { guestId, userId })
            const _ = await this._swPush.requestSubscription({
                serverPublicKey: WEB_PUSH_SERVER_KEY
            })
            const webSubscription = JSON.parse(JSON.stringify(_));
            this.webSubscription = webSubscription;
            this.upsertUserGuestDevice({
                deviceId: result.visitorId,
                userId,
                guestId,
                platform: 'web',
                webSubscription
            }).pipe(
                tap((response) => {
                    this._swPush.messages.subscribe((msg) => {
                        console.log('notification ==========', msg)
                    })
                    this._swPush.notificationClicks.subscribe(arg => {
                        console.log(`notification click ======`, arg);
                        console.log(
                            'Action: ' + arg.action,
                            'Notification data: ' + arg.notification.data,
                            'Notification data.url: ' + arg.notification.data.url,
                            'Notification data.body: ' + arg.notification.body,
                        );
                    });
                })
            ).subscribe();
        } catch (error) {
            console.error(`Error while adding upsert user/guest device`, error);
        }
    };


    public upsertUserGuestDevice(body: any): Observable<any> {
        return this.http.post(`${environment.API_ENDPOINT}user/add-user-guest-device`, body, { responseType: 'json' });
    }

    public getUserContacts(propertyId: number) {
        return this.http.get(`${environment.API_ENDPOINT}user/contacts`, {
            params: {
                'propertyId': String(propertyId)
            }
        })
    }

    public async savePropertyButtonConfig(type: string, name: string, payload): Promise<any> {
        return await this.http.put(`${environment.API_ENDPOINT}configuration/${type}/${name}`, payload, { responseType: 'json' }).toPromise();
    }

    public isDesktop() {
        let platforms = this.platform.platforms();
        if(platforms[0] == "core" || platforms[0] == "mobileweb") {
            return true;
        } else {
            return false;
        }
    }

    setSlug() {
        let subdomain = this.slug.getValue() || '';
        const url = window.location.href;
        const urlObj = new URL(url);
        console.log('platform', this.platform.is)
        if (environment.domainSlug && !url?.includes('pages.dev') && this.isDesktop()) {
            [subdomain] = urlObj.hostname.split('.');
        } else {
            const hash = url.split('#')[1]; // Get the part after the hash
            const params = new URLSearchParams(hash?.split('?')?.[1]); // Get the query parameters part
            const sParam = params.get('s');
            const searchSlug = sParam || 'hotelritz';
            subdomain = searchSlug || subdomain;
            console.log('subdomain ====', subdomain)
        }
        console.log('subdomain', subdomain)
        this.setSlugInService(subdomain);
    }

    setSlugInService(slug: string) {
        this.slug.next(slug);
        this.cookieService.set('slug', slug);
    }
}
