import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from 'rxjs';
declare const webphone_api: any;

@Injectable({
    providedIn: 'root'
})
export class WebPhoneService {
    canCall: boolean;
    private webphoneStopEventSubject$ = new Subject();
    readonly webphoneStopEvents = this.webphoneStopEventSubject$.asObservable();

    constructor() {
        webphone_api.cacheMediaStream = false;
    }

    onlyRegister() {
        webphone_api.register();
    }

    /**
     * This method used to register webphone
     * @param isLoadedCallback call after webphone loaded 
     * @param registerCallback call after webphone register
     * @param onCallStateChangedEvent call after webphone call state change
     */
    async register(isLoadedCallback, registerCallback: any, onCallStateChangedEvent: any) {
        try {
            console.log('======================', webphone_api.getStatus())
            // if (webphone_api.getStatus().includes('Ready')) {
            //     this.setEnginePriority();
            //     isLoadedCallback && isLoadedCallback();
            // }
            webphone_api.onAppStateChange((state: string) => {
                console.log('================= onAppStateChange', state)
                if (state === 'loaded') {
                    this.setEnginePriority();
                    isLoadedCallback && isLoadedCallback();
                } else if (state === 'started') {
                    console.log('==============> register =====', )
                    webphone_api.register();
                } else if (state === 'stopped') {
                    this.webphoneStopEventSubject$.next(true);
                }
            });
            webphone_api.onRegStateChange(async (state: string) => {
                console.log('================= onRegStateChange', state)
                if (registerCallback) {
                    if (state === 'registered') {
                        this.canCall = true
                        registerCallback(true);
                    } else {
                        this.canCall = false;
                        registerCallback(false);
                        webphone_api.stop();
                    }
                }
            });
            webphone_api.onCallStateChange((...data) => {
                console.log('============ onCallStateChange', data);
                onCallStateChangedEvent && onCallStateChangedEvent(data);
            });
            webphone_api.onEvent((...data) => {
                const splitData = data && data[1].split(',');
                console.log('================= onEvent', splitData)
                if (splitData && splitData[1]?.includes("WebphoneIsLoaded")) {
                    this.setEnginePriority();
                    isLoadedCallback && isLoadedCallback();
                } else if (splitData && splitData[0] === 'EVENT' && splitData[2] === "Registered.") {
                    registerCallback(true);
                } else if (splitData && splitData[0] === 'STATUS' && splitData[2] === "Unregistered") {
                    registerCallback(false);
                } else if (splitData && splitData[0] === 'STATUS' && splitData[2] === "Hangup") {
                    onCallStateChangedEvent(["disconnected", 1, ""])
                } else if (data && data[1]?.includes('Starting call to')) {
                    onCallStateChangedEvent(["initializing", 1, data[1].replace(/Starting call to/, '')])
                } else if (data && data[1]?.includes('EVENT, plhandler initiate Call with engine: webrtc, to number: ')) {
                    onCallStateChangedEvent(["initializing", 1, data[1].replace(/EVENT, plhandler initiate Call with engine: webrtc, to number: /, '')])
                }
            })
        } catch (e) {
            this.canCall = false
        }
    }

    /**
     * This method used for start webphone with set required config got after loggedIn
     * @param sipParameters (Key, Value) array of sip parameter
     */
    async onWTCStart(sipParameters: { key: string, value: number | string | undefined | null }[]) {
        console.log('============= sipParameters', sipParameters);
        sipParameters.forEach(({
            key, value
        }) => {
            webphone_api.setparameter(key, value);
        });
        webphone_api.start();
    }

    /**
    * This method used for outbound call
    * @param options (options.to) target number for call
    */
    async call(options: { to: string }) {
        if (!this.canCall) {
            console.log('================ unable to make call', this.canCall)
            return;
        }
        webphone_api.setparameter('destination', options.to);
        webphone_api.setparameter('usecommdevice', 2)
        webphone_api.call(options.to);
    }

    /**
     * This method used for hangup outbound/incoming call
     */
    async hangup() {
        webphone_api.hangup();
    }

    /**
     * This method used for mute/unmute outbound/incoming call
     */
    mute(mute: boolean) {
        webphone_api.mute(mute, 1);
    }

    /**
     * This method used for set sound for character
     * @param character Taped character for dial
     */
    sendDtmf(character: string) {
        webphone_api.dtmf(character)
    }

    /**
     * This method used for accept incoming call
     */
    acceptCall() {
        webphone_api.accept();
    }

    /**
     * This method used for unregister webphone after logout
     */
    async unregister() {
        return await webphone_api.unregister();
    }

    /**
     * This method used for stop webphone not using anymore 
     */
    async stop() {
        return await webphone_api.stop();
    }

    /**
     * This method used for get all connected audio device 
     */
    async getdevicelist() {
        return new Promise((resolve) => {
            webphone_api.getaudiodevicelist(0, (...data) => {
                resolve(data)
            });
        })
    }
    /**
     * This method used for set selected device
     */
    async setdevice(name: string) {
        return webphone_api.setaudiodevice(0, name, 2);
    }
    /**
     * This method used for open device selection popup but we are not using anymore
     */
    async devicepopup() {
        return webphone_api.devicepopup();
    }

    /**
     * This method used for get selected device
     */
    async getdevice() {
        return new Promise((resolve) => {
            webphone_api.getdevice(1, resolve);
        })
    }

    /**
     * This method used for get parameter to check sip parameter set or not
     */
    getParameter(key: string) {
        return webphone_api.getparameter(key)
    }
    /**
     * This method used for set default sip parameters
     */
    setSipDefaultParameter() {
        webphone_api.setparameter('video', 0)
        webphone_api.setparameter('videofacing', 'no');
        webphone_api.setparameter('video_width', 0);
        webphone_api.setparameter('video_height', 0);
        webphone_api.setparameter('video_min_width', 0);
        webphone_api.setparameter('video_min_height', 0);
        webphone_api.setparameter('video_max_width', 0);
        webphone_api.setparameter('video_max_height', 0);
        webphone_api.setparameter('autostart', 0);
        webphone_api.setparameter('nsupgrademode', -1);
        webphone_api.setparameter('forcereregister', 0);
        webphone_api.setparameter('acceptcall_onsharedevice', 0);
        this.setEnginePriority();
    }

    setEnginePriority() {
        webphone_api.setparameter('enginepriority_java', 0)
        webphone_api.setparameter('enginepriority_webrtc', 5)
        webphone_api.setparameter('enginepriority_ns', 0)
        webphone_api.setparameter('enginepriority_flash', 0)
        webphone_api.setparameter('enginepriority_app', 0)
        webphone_api.setparameter('enginepriority_p2p', 0)
        webphone_api.setparameter('enginepriority_accessnum', 0)
        webphone_api.setparameter('enginepriority_nativedial', 0)
        webphone_api.setparameter('enginepriority_otherbrowser', 0)
    }
}