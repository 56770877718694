import { Injectable } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { LocalNotificationActionPerformed, LocalNotifications, NotificationChannel } from '@capacitor/local-notifications';
import { UtilService } from './util.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DeeplinkService } from './deeplink';
// import { AngularFireMessaging } from '@angular/fire/messaging';
declare let window: any;


@Injectable({
  providedIn: 'root',
})
export class FcmProvider {

  devices: Array<any> = [];
  request: any;
  token: string = null
  uid: string = null

  constructor(
    private util: UtilService,
    private http: HttpClient,
    private platform: Platform,
    private router: Router,
    private deeplink: DeeplinkService, 
  //  private afMessaging: AngularFireMessaging
  ) {}

  async init(uid: string) {
    return await new Promise(async (resolve, reject) => {
      try{
        this.setNoticationActions();
        if (this.platform.is('capacitor')) {
          this.request = await PushNotifications.requestPermissions();
          await this.processToken(uid)
        } else {
          if(!this.platform.is('ios') && !/Mac|iPod|iPhone|iPad/.test(navigator.platform)) {
            // this.afMessaging.requestPermission.subscribe(async res => {
            //   this.request = {
            //     'receive': true
            //   }
            //   await this.processToken(uid)
            // }, (error: any) => {
            //   throw new Error(error)
            // })
          } else {
            await this.checkIosPermission(uid)
          }
        }
        resolve({})
      } catch (ex) {
        reject({
          error: ex.error || ex.message || ex
        })
      }
    })
  }

  async checkIosPermission (uid) {
    if('safari' in window && 'pushNotification' in window.safari) {
      this.uid = uid
      let permission: any = window.safari.pushNotification.permission(environment.app_web_url);
      await this.checkSafariPermission(permission)
    }
  }

  async checkSafariPermission(permissionData: any) {
    try {
      if(permissionData.permission == "default") {
        window.safari.pushNotification.requestPermission(
          environment.app_web_url,
          environment.app_web_url,
          {},
          this.checkSafariPermission
        )
      } else if(permissionData.permission === 'denied') {
        throw new Error("permission denied")
      } else if(permissionData.permission === 'granted') {
        await this.processToken(this.uid)
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  listenNotificationEvent() {
    LocalNotifications.addListener('localNotificationActionPerformed', async (notification: LocalNotificationActionPerformed)=>{
      await PushNotifications.removeAllDeliveredNotifications()
      await this.deeplink.action(notification.actionId)
    });
  }

  async setNoticationActions() {
    try{
      await LocalNotifications.checkPermissions();
      if(this.platform.is('capacitor') && this.platform.is('android')) {
        const channelnotifiction: NotificationChannel = {
          id: 'call-notification',
          name: 'call-notifications',
          importance: 4,
          visibility: 1,
          vibration: true,
        };
        await PushNotifications.createChannel(channelnotifiction)
        await LocalNotifications.createChannel(channelnotifiction)
      }
    } catch (ex) {
      throw new Error(ex.error || ex.message || ex)
    }
  }

  async processToken(uid: string) {
    return await new Promise(async (resolve, reject) => {
      try{
        if (this.request['receive']) {
          const token: any = await this.getToken();
          const resp: any = await this.saveToken(uid, token);

          this.showPushNOtification();
          resolve(resp)
        } else {
          throw new Error('Push notification error on register' + this.request);
        }
      } catch (ex) {
        reject({
          error: ex.error || ex.message || ex
        })
      }
    })
  }

  async saveToken(uid: string, token: string) {
    return await new Promise(async (resolve, reject) => {
      try {
        this.token = token;
        this.http.put(
          `${environment.API_ENDPOINT}guest/me/${uid}`, {
            'fcmToken': token,
            appType: (!this.platform.is('capacitor')) ? 'PWA' : (this.platform.is('capacitor') && this.platform.is('android')) ? 'ANDROID' : 'IOS'
          } 
        ).toPromise().then((res: any) => {
          resolve(res);
        }).catch((ex: any) => {
          throw new Error(ex);
        })
      } catch (ex) {
        reject({
          error: ex.error || ex.message || ex
        })
      }
    });
  }

  private async getToken() {
    return await new Promise(async (resolve, reject) => {
      try {
        // On success, we should be able to receive notifications
        if (this.platform.is('capacitor')) {
          await PushNotifications.register();
          await PushNotifications.addListener('registration', (token: Token) => {
            this.token = token.value;
            resolve(token.value);
          });
        } else {
          // this.afMessaging.requestToken.subscribe((token) => {
          //   this.token = token
          //   resolve(token)
          // })
        }
      } catch (ex) {
        console.log("Push notification error" +ex)
        reject(ex);
      }
    });
  }

  async getPushNotification() {
    return await new Promise((resolve, reject) => {
      try {
        if (this.platform.is('capacitor')) {
          PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {
              resolve(notification);
            }
          );
          PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification: ActionPerformed) => {
              this.listenNotificationEvent();
              await PushNotifications.removeAllDeliveredNotifications();
            }
          );
        } else {
          // this.afMessaging.messages.toPromise().then((msg: any) => {
          //   resolve(msg.notification);
          // })
        }
      } catch (ex) {
        if (this.platform.is('capacitor')) {
          PushNotifications.addListener('registrationError', (error: any) => {
            reject('Error on registration: ' + JSON.stringify(error));
          });
        } else {
          reject('Error on registration: ' + JSON.stringify(ex));
        }
      }
    });
  }

  showPushNOtification() {
    this.listenNotificationEvent();
    this.getPushNotification().then(async (data: any) => {
      await this.router.navigate(['dialer']);
      data.data['title'] = data.title || data.data.title
      data.data['body'] = data.body || data.data.body
      data.data['actionType'] = data.data.actionType
      data.data['CallerName'] = data.data.CallerName
      data.data['CallerID'] = data.data.CallerID
      if(data.data.actionType != "normal") data.data.title += ` : ${data.data.CallerName} - ${data.data.CallerID}`;
      await this.setLocalnotificationActionTypes(data.data['actionType'], data.data);

      // this.deeplink.navigateTo(data.data['click_action'])
    }).catch((ex: any) => {
      console.info(`fcm showPushNotification =======>`, ex);
    })
  }

  private async setLocalnotificationActionTypes(actionId: string, data: any) {
    await this.util.showLocalNotification(actionId, data)
  }
}
