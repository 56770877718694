import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CrudService } from '../services/crud.service';
import { UtilService } from '../services/util.service';

@Component({
  selector: 'app-add-occupant',
  templateUrl: './add-occupant.page.html',
  styleUrls: ['./add-occupant.page.scss'],
})
export class AddOccupantPage implements OnInit {

  @Input() mainGuest;
  @Input() propertyId: string;

  addOccupantForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private modalController: ModalController,
    private crudService: CrudService,
    private utilService: UtilService,
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.addOccupantForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.email],
      phoneNumber: ['', Validators.pattern('^[0-9]{6,}$')],
    })
  }

  cancel() {
    this.modalController.dismiss();
  }

  async onSubmit() {
    const { firstName, lastName, email, phoneNumber } = this.addOccupantForm.value;
    const occupant = {
      firstName,
      lastName,
      email,
      phoneNumber,
      room: this.mainGuest.room,
      propertyId: this.mainGuest.propertyId,
      guestId: this.mainGuest.id,
    }

    try {
      await this.utilService.showLoader();
      const result = await this.crudService.saveData(`guest/add-occupant`, occupant).toPromise();
      this.utilService.hideLoader();
      if(result && result.response_code === 200) {
        this.modalController.dismiss(result);
      }
    } catch (error) {
      this.utilService.hideLoader();
      this.utilService.showToast(error.message);
    }

  }

}
