import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusBar } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { ChatService } from '../../services/chat.service';
import { CrudService } from '../../services/crud.service';
import { PhoneService } from '../webphone/phone.service';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { THEME_SETTING } from '../../utils/config';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() orientation: string = "portrait";
  @Input() hideIndicator: boolean = false;

  @Input()
  title: string;

  @Input() backBtn = false;

  // If this is true, ionic will automatically handle the back button event
  // oterwise an event will be emitted
  @Input() isNav = false;

  @Output()
  backButtonClick: EventEmitter<never> = new EventEmitter<never>();
  public isWTCRegistered = false;
  private registerSubscriber: Subscription = null;
  private callingStateSubscriber: Subscription = null;
  public isCallingData = null;
  public propertyConfig: any = {};
  private propertyConfigSubscribe: Subscription = null;
  public dialerThemeSetting = THEME_SETTING;
  public appHomeLogoutLogo = '../assets/icon/hotel-genie-logo.png'
  public currentUserModeValue = '';
  private currentUserModeValueSubscriber: Subscription = null;
  constructor(
    private crudService: CrudService,
    private router: Router,
    private platform: Platform,
    private chatService: ChatService, private phoneService: PhoneService,
    private activatedRoute: ActivatedRoute,
    private _zone: NgZone
  ) { }

  ngOnInit() {
    this.propertyConfigSubscribe = this.crudService.propertyConfig$.pipe(
      tap(async (propertyConfig) => {
        this.propertyConfig = propertyConfig;
        this.dialerThemeSetting = this.propertyConfig?.dialerThemeSetting?.value || this.dialerThemeSetting;
        this.appHomeLogoutLogo = this.propertyConfig?.appHomeLogoutLogo?.value || '../assets/icon/hotel-genie-logo.png';
      })
    ).subscribe();
    this.currentUserModeValueSubscriber = this.crudService.currentUserMode.pipe((tap((currentUserModeValue) => {
      this.currentUserModeValue = currentUserModeValue
    }))).subscribe();
    this.registerSubscriber = this.phoneService.isUserRegistered$
    .pipe(tap((value: boolean) => {
      this.isWTCRegistered  = value;
    })).subscribe();
    this.callingStateSubscriber = this.phoneService.isCalling$.pipe(
      tap((data) => {
        this._zone.run(() => {
          this.isCallingData = data;
          if (data?.callType === 'incoming') {
            this.goto();
          }
        });
      }),
    ).subscribe();
   
  }

  ngOnDestroy() {
    this.registerSubscriber?.unsubscribe();
    this.callingStateSubscriber?.unsubscribe();
    this.propertyConfigSubscribe?.unsubscribe();
    this.currentUserModeValueSubscriber?.unsubscribe();
  }


  goto() {
    console.log('activatedRoute', this.activatedRoute)
    if (this.orientation == 'portrait') this.router.navigateByUrl("/dialer")
  }

  backButtonClicked() {
    this.backButtonClick.emit();
  }

  async logout() {
    this._zone.run(async () => {
      const user = this.crudService.currentUserValue;
      console.log(user)
      if (user && this.crudService.currentUserModeValue === 'staff') {
        await this.crudService.logout('user/logout', user).toPromise();
      }
      this.crudService.setAuthToken = null;
      this.chatService.onDisconnect();
      localStorage.clear();
      await this.phoneService.unregister();
      if(this.crudService.currentUserModeValue === 'staff') {
        this.router.navigate(["admin"]);
      } else {
        this.router.navigate(['login']);
      }
    });
  }
}
