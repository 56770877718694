export const WEB_PUSH_SERVER_KEY = 'BPOcQ21Q_EK69foz5rR_GpbHsVF5Icf1kaSATeLaeWH4M6Fh3QLcRFVljl2zlmMvEf5bcMgJCoDZwkH1OJXMH3Q';
export const GOOGLE_API_KEY = 'AIzaSyBdOV-WIkBRaiVjfvv4PZWMM4gJwgy3ySk';
export const THEME_SETTING = {
    backgroundColor: '#ffffff',
    buttonBackgroundColor: '#000000',
    buttonTextColor: '#ffffff',
    dialButtonTextColor: '#ffffff',
    dialButtonBackgroundColor: '#90ee90',
    buttonAlphabetColor: '#d3d3d3',
    inputBackgroundColor: '#ffffff',
    inputColor: '#000000',
    instructionTextColor: '#000000',
    suggestionTextColor: '#000000',
    headerFooterThemeColor: '#000f4e'
};