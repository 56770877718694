import { Component } from '@angular/core';
import { NavController, NavParams, Platform } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ModalService } from '../../component/modal/modal.service';
import { CrudService } from '../../services/crud.service';
import { UtilService } from '../../services/util.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
    selector: 'alice-service-modal',
    templateUrl: 'alice-service.html',
    styleUrls: ['alice-service.scss'],
    providers: [InAppBrowser]
})
export class AliceServiceModal {
    propertyId: string = ''
    room: string = ''
    aliceConfiguration: any = null
    maxHeight = (this._platform.height() * 0.6) + 'px';
    maxImgHeight = (this._platform.height() * 0.2) + 'px';
    selectedOption: any = {};
    constructor(private navCtrl: NavController,
        private modalCtrl: ModalController,
        private crudService: CrudService,
        private utilService: UtilService,
        private navParams: NavParams,
        private _platform: Platform,
        private _modalService: ModalService) {
        if (this.navParams.get("aliceConfiguration")) {
            this.aliceConfiguration = this.navParams.get("aliceConfiguration");
        }
    }

    ngOnInit() {
        this.propertyId = localStorage.getItem('propertyId')
        this.room = localStorage.getItem('room');
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    async requestRoomService(selectedOption) {
        this.selectedOption = selectedOption;
        if (this.selectedOption.hasQuantity) {
            this.roomServiceQuantityType(this.selectedOption);
        } else {
            this.showConfirmation();
        }
    }

    async roomServiceType() {
        try {
            this.utilService.showLoader();
            let requestBody = {
                "propertyId": this.propertyId,
                "aliceServiceId": this.aliceConfiguration.aliceServiceId,
                "aliceOptionId":  this.aliceConfiguration.aliceOptionId,
                "aliceSubOptionId": this.selectedOption.id || this.selectedOption.name,
                "message": this.selectedOption.message || '',
                "requester": "Guest",
                "guestId": Number(localStorage.getItem('guestId')),
                "quantity": this.selectedOption.selectedQuantity?.value || 0
            };
            let response = await this.crudService.saveData('alice/ticket', requestBody).toPromise();
            this.utilService.hideLoader();
            this.utilService.showToast('Request save successfully');
        } catch (error) {
            this.utilService.hideLoader();
            this.utilService.showToast(error?.error?.message || error?.message || error);
        }
    }

    async roomServiceQuantityType(btn: any) {
        this.selectedOption.modalId = 'alice-service-quantity';
        this.selectedOption.roomServiceQuantityTypeOpen = true;
        this.selectedOption.selectOptions = Array.from({ length: btn?.quantity || 0 }, (_, index) => ({
            value: index + 1,
            label: `${index + 1}`
        }));
        this.selectedOption.selectedQuantity = this.selectedOption?.selectOptions?.length ? this.selectedOption.selectOptions[0] : 0;
        this._modalService.open('alice-service-quantity');
    }


    async showConfirmation() {
        this._modalService.open('confirmation-popup');
    }

    async onYes() {
        this._modalService.close('confirmation-popup');
        if (this.selectedOption.modalId)
            this._modalService.close(this.selectedOption.modalId);
        await this.roomServiceType();
    }

    onNo() {
        this._modalService.close('confirmation-popup');
    }
}
