import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
import { CrudService } from '../../services/crud.service';
import { Subscription } from 'rxjs';
import { THEME_SETTING } from '../../utils/config';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() adminPage: boolean;
  @Input() adminHome: boolean;
  @Input() roomid: string;
  @Output() emergencyClick: EventEmitter<never> = new EventEmitter<never>();
  orientationSubscribe: Subscription;
  propertyConfigSubscribe: Subscription;
  orientation: string = '';
  propertyConfig: any = null;
  public dialerThemeSetting = THEME_SETTING;
  public currentUserModeValue = '';
  private currentUserModeValueSubscriber: Subscription = null;
  constructor(public crudService: CrudService, private utilService: UtilService) {

  }

  ngOnInit() {
    this.orientationSubscribe = this.utilService.getOrientation().pipe(
      tap((orientation) => {
        this.orientation = orientation;
      })
    ).subscribe();
    this.currentUserModeValueSubscriber = this.crudService.currentUserMode.pipe((tap((currentUserModeValue) => {
      this.currentUserModeValue = currentUserModeValue
    }))).subscribe()
    this.propertyConfigSubscribe = this.crudService.propertyConfig$.pipe(
      tap((propertyConfig) => {
        this.propertyConfig = propertyConfig;
        this.dialerThemeSetting = this.propertyConfig?.dialerThemeSetting?.value || this.dialerThemeSetting;
      })
    ).subscribe();
  }

  checkWebRtcFeature() {
    if (this.crudService.propertyConfig$.value['featureWebRTC'] && this.crudService.propertyConfig$.value['featureWebRTC'].value == false) {
      return true
    }
    return false
  }


  ngOnDestroy(): void {
    this.orientationSubscribe.unsubscribe();
    this.propertyConfigSubscribe?.unsubscribe();
    this.currentUserModeValueSubscriber?.unsubscribe();
  }

  handleEmergency() {
    this.emergencyClick.emit();
  }
}
